// -----------------------------------------------------------------------------
// Variables
// -----------------------------------------------------------------------------

// Spacing
//

$spacer: 1rem;

// Fonts
//

$font-family-serif: "Lato", serif;
$font-family-brand: $font-family-serif;
$headings-font-family: $font-family-serif;
$headings-font-weight: 600;

// Products list
//

$product-list-margin-bottom: $spacer * 1.5;
$product-list-item-margin-bottom: $spacer * 1;
