// -----------------------------------------------------------------------------
// Header of the site
// -----------------------------------------------------------------------------

// Brand
//

.example-header-logo {
    font-family: $font-family-brand;
    font-size: $h2-font-size;
}
