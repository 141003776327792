// -----------------------------------------------------------------------------
// Category page
// -----------------------------------------------------------------------------

.example-products-list {
    margin-bottom: $product-list-margin-bottom;
}

.example-products-list-header {
    margin-bottom: $product-list-margin-bottom;
    padding: $spacer;
    background-color: $gray-200;
}

.example-products-list-heading {
    margin-bottom: 0;

    font-size: $h2-font-size;

    @include media-breakpoint-up(sm) {
        font-size: $h1-font-size;
    }
}

.example-products-list-item {
    margin-bottom: $product-list-item-margin-bottom;

    + .example-products-list-item {
        padding-top: $product-list-item-margin-bottom;
        border-top: $border-width solid $border-color;
    }

    @include media-breakpoint-up(sm) {
        display: flex;
        align-items: flex-start;
    }
}

.example-product-item {}

.example-product-item-img {
    margin-right: 0;
    margin-bottom: $spacer;

    @include media-breakpoint-up(sm) {
        width: 172px;
        margin-right: $spacer;
        margin-bottom: 0;
    }
}

.example-product-item-header {
    @include media-breakpoint-up(sm) {
        display: flex;
        justify-content: space-between;
    }
}

.example-product-item-heading {
    font-size: $h3-font-size;

    > a {
        color: inherit;
        text-decoration: none;
    }

    @include media-breakpoint-up(sm) {
        font-size: $h2-font-size;
    }
}

.example-product-item-body {
    flex: 1;
}

.example-product-item-price {
    margin-bottom: $headings-margin-bottom;
    font-size: $h3-font-size;
    line-height: $headings-line-height;
    text-align: right;

    @include media-breakpoint-up(sm) {
        font-size: $h2-font-size;
    }
}

.example-product-item-desc {

}

.example-product-item-footer {
    display: flex;

    @include media-breakpoint-up(sm) {
        justify-content: flex-end;
    }
}

.example-product-item-btn {
    + .example-product-item-btn {
        margin-left: $spacer;
    }
}

.example-product-item-more {}

.example-product-item-buy {}
