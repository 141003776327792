// -----------------------------------------------------------------------------
// Footer of the site
// -----------------------------------------------------------------------------

.example-footer {
    padding-top: $spacer;
    padding-bottom: $spacer;
    border-top: $border-width solid $border-color;
    text-align: center;
}
