/*------------------------------------------------------------------
 * Theme Name: iosoon - coming soon page templates
 * Theme URI: http://www.brandio.io/envato/iosoon
 * Author: Brandio
 * Author URI: http://www.brandio.io/
 * Copyright 2018 Brandio.
 -------------------------------------------------------------------*/
/*------------------------------------------------------------------
[Table of contents]

1. General Styles.
2. Responsive Styles.
-------------------------------------------------------------------*/
/* -----------------------------------
    1 - General Styles
------------------------------------*/
.form-body {
    background-color: #fff;
}

.form-body.gradient-bg {
    background-repeat: repeat-x;
    background-image: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.website-logo {
    display: inline-block;
    top: 50px;
    left: 50px;
    right: initial;
    bottom: initial;
}

.website-logo img {
    width: 200px;
}

.website-logo .logo {
    background-image: url("../images/clever-app-logo-colored.png");
}

.website-logo .logo img {
    width: 200px;
}

.other-links span {
    color: #000;
}

.other-links a {
    color: #000;
}

.other-links a i {
    color: #CBCBCB;
}

.website-logo-inside img {
    width: 200px;
}

.website-logo-inside .logo {
    background-image: url("../images/clever-app-logo-colored.png");
}

.website-logo-inside .logo img {
    width: 200px;
}

.img-holder {
    display: inline-block;
    width: 550px;
    background-color: #fff;
}

.img-holder:before {
    background-image: url(../images/cloud-bg.svg);
    background-size: 130%;
}

.img-holder .info-holder h3 {
    color: #000;
}

.img-holder .info-holder p {
    color: #000;
}

.img-holder .info-holder img {
    max-width: 378px;
}

.img-holder .bg {
    opacity: 0.23;
    background-image: none;
}

.form-holder {
    margin-left: 550px;
}

.form-holder .form-content ::-webkit-input-placeholder {
    color: #000;
}

.form-holder .form-content :-moz-placeholder {
    color: #000;
}

.form-holder .form-content ::-moz-placeholder {
    color: #000;
}

.form-holder .form-content :-ms-input-placeholder {
    color: #000;
}

.form-holder.custom-bg:before {
    background-color: #F9EAE9;
}

.form-holder.custom-bg:after {
    background-color: #F9EAE9;
}

.form-control ::-webkit-input-placeholder {
    color: #000;
}

.form-control :-moz-placeholder {
    color: #000;
}

.form-control ::-moz-placeholder {
    color: #000;
}

.form-control :-ms-input-placeholder {
    color: #000;
}

.form-content {
    background-color: #fff;
}

.form-content .form-group {
    color: #000;
}

.form-content .form-items {
    max-width: 440px;
    text-align: left;
}

.form-content h3 {
    color: #000;
    text-align: left;
}

.form-content h5 {
    color: #000;
}

.form-content p {
    color: #000;
    text-align: left;
}

.form-content label {
    color: #000;
    text-align: left;
}

.form-content .page-links a {
    color: #000;
}

.form-content .page-links a:after {
    background-color: rgba(255, 255, 255, 0.5);
}

.form-content .page-links a.active:after {
    background-color: #000;
}

.form-content .page-links a:hover:after, .form-content .page-links a:focus:after {
    background-color: #000;
}

.form-content input, .form-content .dropdown-toggle.btn-default {
    border: 0;
    background-color: #F7F7F7;
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    color: #000;
}

.form-content input:hover, .form-content input:focus, .form-content .dropdown-toggle.btn-default:hover, .form-content .dropdown-toggle.btn-default:focus {
    border: 0;
    background-color: #f1f0f0;
    color: #000;
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.form-content textarea {
    background-color: #F7F7F7;
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    border: 0;
    color: #000;
}

.form-content textarea:hover, .form-content textarea:focus {
    border: 0;
    background-color: #f1f0f0;
    color: #000;
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.form-content input[type="checkbox"]:not(:checked) + label, .form-content input[type="checkbox"]:checked + label, .form-content input[type="radio"]:not(:checked) + label, .form-content input[type="radio"]:checked + label {
    color: #000;
}

.form-content input[type="checkbox"]:checked + label, .form-content input[type="radio"]:checked + label {
    color: #000;
}

.form-content input[type="checkbox"]:checked + label:before, .form-content input[type="radio"]:checked + label:before {
    background: #fff;
    border: 2px solid #000;
}

.form-content input[type="checkbox"]:not(:checked) + label:before, .form-content input[type="radio"]:not(:checked) + label:before {
    background: transparent;
    border: 2px solid #000;
}

.form-content input[type="checkbox"]:checked + label:after, .form-content input[type="checkbox"]:not(:checked) + label:after {
    color: #000;
}

.form-content input[type="radio"]:checked + label:after, .form-content input[type="radio"]:not(:checked) + label:after {
    background-color: #000;
}

.form-content .custom-options input[type="checkbox"]:not(:checked) + label, .form-content .custom-options input[type="checkbox"]:checked + label, .form-content .custom-options input[type="radio"]:not(:checked) + label, .form-content .custom-options input[type="radio"]:checked + label {
    color: #606060;
    background-color: #F7F7F7;
}

.form-content .custom-options input[type="checkbox"]:checked + label, .form-content .custom-options input[type="radio"]:checked + label {
    color: #fff;
    background-color: #57D38C;
    -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16);
}

.form-content .form-button .ibtn {
    background-color: #57D38C;
    color: #fff;
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.form-content .form-button .ibtn:hover, .form-content .form-button .ibtn:focus {
    -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0);
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0);
    background-color: #43ce7e;
}

.form-content .form-button a {
    color: #000;
}

.form-content .btn.btn-primary {
    color: #fff;
    background-color: #57D38C;
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.form-content .btn.btn-primary:hover, .form-content .btn.btn-primary:focus {
    background-color: #4bd084;
    -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0);
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0);
}

.form-content .form-sent .tick-holder .tick-icon {
    background-color: rgba(255, 255, 255, 0);
}

.form-content .form-sent .tick-holder .tick-icon:before {
    background-color: #fff;
}

.form-content .form-sent .tick-holder .tick-icon:after {
    background-color: #fff;
}

.form-content .form-sent h3 {
    color: #000;
}

.form-content .form-sent p {
    color: #000;
}

.form-content .form-sent .info-holder {
    color: #000;
    border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.form-content .form-sent .info-holder span {
    color: #000;
}

.form-content .form-sent .info-holder a {
    color: #000;
}

.form-content .time-counter .count {
    border-right: 1px solid #D5D5D5;
}

.form-content .time-counter .count .num {
    color: #000;
}

.form-content .time-counter .count .label {
    color: #000;
}

@keyframes tick-anime3 {
    0% {
        background-color: rgba(255, 255, 255, 0);
        -webkit-transform: rotate(35deg) scale(2);
        -moz-transform: rotate(35deg) scale(2);
        -ms-transform: rotate(35deg) scale(2);
        transform: rotate(35deg) scale(2);
    }

    100% {
        background-color: rgba(255, 255, 255, 0.2);
        -webkit-transform: rotate(45deg) scale(1);
        -moz-transform: rotate(45deg) scale(1);
        -ms-transform: rotate(45deg) scale(1);
        transform: rotate(45deg) scale(1);
    }
}

.alert {
    color: #fff;
}

.alert.alert-primary {
    background-color: rgba(226, 240, 255, 0.1);
    border-color: #fff;
}

.alert.alert-primary hr {
    border-top-color: #fff;
}

.alert.alert-secondary {
    background-color: rgba(240, 240, 240, 0.1);
    border-color: #fff;
}

.alert.alert-secondary hr {
    border-top-color: #fff;
}

.alert.alert-success {
    background-color: rgba(247, 255, 240, 0.1);
    border-color: #fff;
}

.alert.alert-success hr {
    border-top-color: #fff;
}

.alert.alert-danger {
    background-color: rgba(255, 250, 250, 0.1);
    border-color: #fff;
}

.alert.alert-danger hr {
    border-top-color: #fff;
}

.alert.alert-warning {
    background-color: rgba(255, 248, 225, 0.1);
    border-color: #fff;
}

.alert.alert-warning hr {
    border-top-color: #fff;
}

.alert.alert-info {
    background-color: rgba(220, 237, 241, 0.1);
    border-color: #fff;
}

.alert.alert-info hr {
    border-top-color: #fff;
}

.alert.alert-light {
    background-color: rgba(254, 254, 254, 0.1);
    border-color: #fff;
}

.alert.alert-light hr {
    border-top-color: #fff;
}

.alert.alert-dark {
    background-color: rgba(214, 216, 217, 0.1);
    border-color: #fff;
}

.alert.alert-dark hr {
    border-top-color: #fff;
}

.alert.with-icon.alert-primary:before {
    color: #fff;
}

.alert.with-icon.alert-secondary:before {
    color: #fff;
}

.alert.with-icon.alert-success:before {
    color: #fff;
}

.alert.with-icon.alert-danger:before {
    color: #fff;
}

.alert.with-icon.alert-warning:before {
    color: #fff;
}

.alert.with-icon.alert-info:before {
    color: #fff;
}

.alert.with-icon.alert-light:before {
    color: #fff;
}

.alert.with-icon.alert-dark:before {
    color: #fff;
}

.alert a.alert-link, .alert a {
    color: #fff;
}

.alert .close {
    color: #fff;
}

.alert .close span {
    color: #fff;
}

.form-body.without-side .img-holder .info-holder img {
    display: none;
}

.form-body.without-side .form-content .page-links a:after {
    background-color: rgba(222, 222, 222, 0.7);
}

.form-body.without-side .form-content .page-links a.active:after {
    background-color: #29A4FF;
}

.form-body.without-side .form-content .page-links a:hover:after, .form-body.without-side .form-content .page-links a:focus:after {
    background-color: #29A4FF;
}

.form-body.without-side .form-content .form-button .ibtn {
    background-color: #007AFF;
    color: #fff;
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
}

.form-body.without-side .form-content .form-button .ibtn:hover, .form-body.without-side .form-content .form-button .ibtn:focus {
    -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
    background-color: #006ee6;
}

/* -----------------------------------
  2 - Responsive Styles
------------------------------------*/
@media (max-width: 992px) {
    .img-holder {
        display: none;
    }

    .form-holder {
        margin-left: 0;
    }

    .website-logo {
        top: 50px;
        left: 50px;
        right: initial;
        bottom: initial;
    }

    .website-logo .logo {
        background-image: url("../images/clever-app-logo-colored.png");
    }

    .form-body.without-side .website-logo .logo {
        background-image: url("../images/clever-app-logo-colored.png");
    }
}
